<template>
  <div class="statistics-monitor-base-container">
    <ul class="horizontal-nav-small">
      <li>
        <router-link to="/base/statistics/monitor/body">人体成分</router-link>
      </li>
      <li>
        <router-link to="/base/statistics/monitor/sugar">血糖</router-link>
      </li>
      <li>
        <router-link to="/base/statistics/monitor/uric">尿酸</router-link>
      </li>
      <li>
        <router-link to="/base/statistics/monitor/cho">血脂</router-link>
      </li>
      <li>
        <router-link to="/base/statistics/monitor/height">体质</router-link>
      </li>
      <li>
        <router-link to="/base/statistics/monitor/blood">血压</router-link>
      </li>
      <li>
        <router-link to="/base/statistics/monitor/grip">握力</router-link>
      </li>
      <li>
        <router-link to="/base/statistics/monitor/capacity">肺活量</router-link>
      </li>
      <li>
        <router-link to="/base/statistics/monitor/balanced">平衡能力</router-link>
      </li>
    </ul>
    <div class="content-in-small">
      <router-view></router-view>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
	data() {
		return {};
	},
};
</script>

<style lang="css" scoped>
.horizontal-nav-small {
  text-align: center;
  margin-bottom: 1px;
}
.horizontal-nav-small>li {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  margin: 0 10px;
}
.horizontal-nav-small>li>a {
  display: block;
  padding: 0 12px;
  border-bottom: 1px solid #fff;
  color: #666;
}
.horizontal-nav-small>li>a.active {
  color: #ff8400;
  border-bottom-color: #ff8400;
}
.statistics-monitor-base-container .content-in-small {
  border: 1px solid #eee;
  padding: 20px 10px;
}
</style>
